// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './contexts/AuthContexts';

const PrivateRoute = () => {
    const { currentUser } = useAuth();

    if (currentUser === null) {
        // User is not authenticated, redirect to login
        return <Navigate to="/login" />;
    }

    // User is authenticated, render the protected route
    return <Outlet />;
};

export default PrivateRoute;
