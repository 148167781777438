import React, { useState, useEffect } from 'react';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc,
  updateDoc, 
  deleteDoc, 
  addDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import AddEventModal from './AddEventModal';
import EditEventModal from './EditEventModal';
import { RRule } from 'rrule';
import { Button, ButtonGroup } from 'react-bootstrap'; // Import Button components

import './styles/Schedule.css';

const db = getFirestore();
const auth = getAuth();

export const Schedule = () => {
  const [events, setEvents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeMembers, setActiveMembers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [calendarView, setCalendarView] = useState('timeGridDay'); // Add this state

  useEffect(() => {
    fetchEvents();
    fetchClasses();
    fetchActiveMembers();
  }, []);

  useEffect(() => {
    console.log("Modal state changed:", showModal);
  }, [showModal]);

  const fetchEvents = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const eventsRef = collection(db, 'events');
    const q = query(eventsRef, where('createdBy', '==', user.uid));
    const querySnapshot = await getDocs(q);

    const fetchedEvents = [];
    querySnapshot.forEach((doc) => {
      const event = { id: doc.id, ...doc.data() };
      if (event.recurrence) {
        try {
          // Try to parse the RRULE string
          const rruleOptions = RRule.parseString(event.recurrence);
          const rule = new RRule({
            ...rruleOptions,
            dtstart: new Date(event.start)
          });

          const occurrences = rule.between(
            new Date(), // Start date (now)
            new Date(new Date().setFullYear(new Date().getFullYear() + 1)) // End date (1 year from now)
          );

          occurrences.forEach((date, index) => {
            const eventDuration = new Date(event.end) - new Date(event.start);
            fetchedEvents.push({
              ...event,
              id: `${event.id}_${index}`,
              title: event.title,
              start: date.toISOString(),
              end: new Date(date.getTime() + eventDuration).toISOString(),
              recurringEventId: event.id,
              extendedProps: {
                class_name: event.class_name,
                memberName: event.memberName
              }
            });
          });
        } catch (error) {
          console.error('Error parsing RRULE:', error);
          // If parsing fails, add the event as a non-recurring event
          fetchedEvents.push({
            ...event,
            title: event.title,
            extendedProps: {
              class_name: event.class_name,
              memberName: event.memberName
            }
          });
        }
      } else {
        fetchedEvents.push({
          ...event,
          title: event.title,
          extendedProps: {
            class_name: event.class_name,
            memberName: event.memberName
          }
        });
      }
    });

    setEvents(fetchedEvents);
  };

  const fetchClasses = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const q = query(collection(db, 'classes'), where('createdBy', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const classesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('Fetched classes:', classesData);
      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchActiveMembers = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const q = query(
        collection(db, 'members'), 
        where('createdBy', '==', user.uid),
        where('active_status', '==', 'active')
      );
      const querySnapshot = await getDocs(q);
      const membersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setActiveMembers(membersData);
    } catch (error) {
      console.error('Error fetching active members:', error);
    }
  };

  const handleAddEvent = () => {
    setShowModal(true);
    
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveEvent = async (eventData) => {
    console.log("handleSaveEvent called with data:", eventData);
    const user = auth.currentUser;
    if (!user) {
      console.log("No user logged in");
      return;
    }

    try {
      const selectedClass = classes.find(c => c.id === eventData.classId);
      const selectedMember = activeMembers.find(m => m.id === eventData.memberId);

      const eventToSave = {
        title: eventData.event_title,
        classId: eventData.classId,
        class_name: selectedClass ? selectedClass.class_name : '',
        inventoryType: selectedClass ? selectedClass.inventoryType : 'default',
        memberId: eventData.memberId,
        memberName: selectedMember ? selectedMember.contact_name : '',
        start: eventData.start.toISOString(),
        end: eventData.end.toISOString(),
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        activeStatus: 'active',
        recurrence: eventData.recurrence || null
      };

      if (user.communityId) {
        eventToSave.communityId = user.communityId;
      }

      await addDoc(collection(db, 'events'), eventToSave);
      fetchEvents(); // Refresh the events list
      setShowModal(false);
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const spanishMonths = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const spanishDays = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];

  const getSpanishMonth = (date) => {
    return spanishMonths[date.getMonth()];
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const getWeekDates = (date) => {
    const curr = new Date(date);
    const first = curr.getDate() - curr.getDay() + 1;
    const week = [];
    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(curr.setDate(first + i));
      week.push(nextDay);
    }
    return week;
  };

  const weekDates = getWeekDates(selectedDate);

  const today = new Date();

  const handleDateClick = (arg) => {
    setSelectedDate(arg.date);
    setShowModal(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    console.log('Event title:', event.title);
    console.log('Event time:', eventInfo.timeText);
    console.log('Event class:', event.extendedProps.class_name);
    console.log('Event member:', event.extendedProps.memberName);
    
    return (
      <div className="event-content">
        <div className="event-title">{event.title}</div>
        <div className="event-time">
          {eventInfo.timeText}
        </div>
        <div className="event-details">
          {event.extendedProps.class_name} - {event.extendedProps.memberName}
        </div>
      </div>
    );
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setShowEditModal(true);
  };

  const handleEventEdit = async (updatedEventData) => {
    try {
      const eventRef = doc(db, 'events', updatedEventData.id);
      await updateDoc(eventRef, {
        event_title: updatedEventData.event_title,
        start: updatedEventData.start.toISOString(),
        end: updatedEventData.end.toISOString(),
        classId: updatedEventData.classId,
        class_name: updatedEventData.class_name,
        memberId: updatedEventData.memberId,
        memberName: updatedEventData.memberName,
        // Add any other fields you want to update
      });
      await fetchEvents(); // Refresh events
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      // Delete the event from your database
      await deleteDoc(doc(db, 'events', eventId));
      
      // Remove the event from the local state
      setEvents(prevEvents => prevEvents.filter(event => event.id !== eventId));
      
      setShowEditModal(false);
    } catch (error) {
      console.error("Error deleting event: ", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handleViewChange = (view) => {
    setCalendarView(view);
  };

  return (
    <div className="schedule-page">
      <div className="view-toggle">
        <ButtonGroup>
          <Button
            variant={calendarView === 'timeGridDay' ? 'primary' : 'secondary'}
            onClick={() => handleViewChange('timeGridDay')}
          >
            Day
          </Button>
          <Button
            variant={calendarView === 'timeGridWeek' ? 'primary' : 'secondary'}
            onClick={() => handleViewChange('timeGridWeek')}
          >
            Week
          </Button>
        </ButtonGroup>
      </div>
      <FullCalendar
        key={calendarView} // Add this line
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={calendarView}
        views={{
          timeGridDay: { buttonText: 'Day' },
          timeGridWeek: { buttonText: 'Week' }
        }}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: '' // Remove default view switcher
        }}
        initialDate={selectedDate}
        events={events}
        height="auto"
        allDaySlot={false}
        slotDuration="00:30:00"
        slotLabelInterval="01:00"
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        }}
        dayHeaderFormat={{ weekday: 'long', month: 'long', day: 'numeric', omitCommas: true }}
        titleFormat={{ month: 'long', day: 'numeric' }}
        dateClick={handleDateClick}
        eventContent={renderEventContent}
        contentHeight="auto"
        expandRows={true}
        eventClick={handleEventClick}
      />
      <div className="fab-container">
        <button className="fab" onClick={() => setShowModal(true)}>+</button>
      </div>
      <AddEventModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSave={handleSaveEvent}
        selectedDate={selectedDate}
        classes={classes}
        activeMembers={activeMembers}
      />
      <EditEventModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleDelete={handleDeleteEvent}
        event={selectedEvent}
      />
    </div>
  );
};

export default Schedule;


