import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import AddPaymentModal from './AddPaymentModal';
import { getAuth } from 'firebase/auth';
import { db } from './config/firebase-config';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import './styles/CatalogCard.css';
import './styles/SectionHeader.css';
import './styles/PaymentList.css';
import Payments from './icons/payments.svg'; // Add this import

const PaymentList = ({ onAddPayment }) => {
  const [payments, setPayments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [classes, setClasses] = useState([]);
  const [filterMethod, setFilterMethod] = useState('Todos');

  useEffect(() => {
    fetchPayments();
    fetchClasses();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = async (paymentData) => {
    setShowModal(false);
    if (paymentData) {
      console.log('Payment data before processing:', paymentData);
      if (paymentData.paymentMethod.toLowerCase() === 'liga de pago') {
        paymentData.status = 'Enviado';
      } else {
        paymentData.status = 'Pagado';
        // Update member status to 'Corriente'
        await updateMemberStatus(paymentData.clientId, 'Corriente');
      }
      console.log('Payment data after processing:', paymentData);
      setPayments(prevPayments => [paymentData, ...prevPayments]);
    }
    fetchPayments();
  };

  const updateMemberStatus = async (memberId, newStatus) => {
    if (!memberId) {
      console.error('No member ID provided');
      return;
    }

    const memberRef = doc(db, 'members', memberId);
    try {
      await updateDoc(memberRef, { status: newStatus });
      console.log(`Member ${memberId} status updated to ${newStatus}`);
    } catch (error) {
      console.error('Error updating member status:', error);
    }
  };

  const fetchPayments = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('No user logged in!');
      return;
    }

    const q = query(
      collection(db, 'payments'),
      where("createdBy", "==", user.uid)
    );

    try {
      const querySnapshot = await getDocs(q);
      const paymentsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date?.toDate(), // Convert Firestore Timestamp to Date
          status: data.status || 'Pendiente' // Set default status to 'Pendiente'
        };
      });
      
      // Sort payments by date, most recent first
      const sortedPayments = paymentsData.sort((a, b) => b.date - a.date);
      
      setPayments(sortedPayments);
      console.log('Fetched and sorted payments:', sortedPayments);
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
  };

  const fetchClasses = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('No user logged in!');
      return;
    }

    const q = query(
      collection(db, 'classes'),
      where("createdBy", "==", user.uid)
    );

    try {
      const querySnapshot = await getDocs(q);
      const classesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const getPaymentMethodClass = (method) => {
    switch (method.toLowerCase()) {
      case 'efectivo':
        return 'payment-method-efectivo';
      case 'tarjeta':
        return 'payment-method-tarjeta';
      case 'transferencia':
        return 'payment-method-transferencia';
      case 'enlace de pago':
      case 'liga de pago':
        return 'payment-method-liga';
      default:
        return '';
    }
  };

  const formatCurrency = (amount) => {
    if (amount === undefined || amount === null) {
      return 'N/A';
    }
    return `$${Number(amount).toFixed(2)}`;
  };

  const formatDate = (date) => {
    if (!date || !(date instanceof Date)) {
      return 'Fecha no disponible';
    }
    
    const months = [
      'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN',
      'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'
    ];
    
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    
    return `${day} ${month} ${year} · ${hour}:${minute}`;
  };

  const paymentMethods = ['Todos', 'Efectivo', 'Tarjeta', 'Transferencia', 'Liga de pago'];

  const filteredPayments = payments.filter(payment => 
    filterMethod === 'Todos' || payment.paymentMethod.toLowerCase() === filterMethod.toLowerCase()
  );

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'pagado':
        return 'status-paid';
      case 'enviado':
        return 'status-sent';
      case 'pendiente':
      default:
        return 'status-pending';
    }
  };

  const getStatusText = (status) => {
    switch (status.toLowerCase()) {
      case 'pagado':
        return 'Pagado';
      case 'enviado':
        return 'Enviado';
      case 'pendiente':
      default:
        return 'Pendiente';
    }
  };

  return (
    <div>
      <div className="section-header">
        <h3 className='section-title'>{filteredPayments.length} pagos</h3>
        <div className="filter-container">
          <select 
            className="filter-select"
            value={filterMethod}
            onChange={(e) => setFilterMethod(e.target.value)}
          >
            <option value="" disabled>Filtrar</option>
            {paymentMethods.map((method) => (
              <option key={method} value={method}>{method}</option>
            ))}
          </select>
        </div>
      </div>

      <AddPaymentModal
        show={showModal}
        handleClose={handleCloseModal}
        isFromPaymentList={true}
        classes={classes}
      />

      {filteredPayments.length > 0 ? (
        <div className="payment-list">
          {filteredPayments.map((payment) => (
            <div key={payment.id} className="payment-item">
              <div className="payment-info">
                <h3>{payment.clientName}</h3>
                <p className="payment-details">
                  {payment.itemsPaid && payment.itemsPaid.length > 0 && (
                    <>
                      {payment.itemsPaid.map((item, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && ', '}
                          {item.name}
                        </React.Fragment>
                      ))}
                      {payment.paymentMethod && (
                        <>
                          <span className="interpunct"> · </span>
                          <span className="payment-method">{payment.paymentMethod}</span>
                        </>
                      )}
                    </>
                  )}
                </p>
                <p>{formatDate(payment.date)}</p>
              </div>
              <div className="payment-right">
                <p className="payment-amount">{formatCurrency(payment.totalAmount)}</p>
                <div className="payment-status">
                  <span className={`tag ${getStatusClass(payment.status)}`}>
                    {getStatusText(payment.status)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-payments-container">
          <img src={Payments} alt="No payments" className="no-payments-icon" />
          <h2>Aún no has registrado pagos</h2>
          <p>¡Hazlo más fácil! Registra tus pagos y toma el control de tus transacciones.</p>
          <button className="add-payment-button" onClick={handleShowModal}>
            + Agregar
          </button>
        </div>
      )}

      {/* Remove or comment out the FAB button */}
      {payments.length > 0 && (
        <div className="fab-container">
          <button className="fab" onClick={() => handleShowModal(null)}>+</button>
        </div>
      )}
    </div>
  );
};

export default PaymentList;