import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import AddClassModal from './AddClassModal';
import { getAuth } from 'firebase/auth';
import { db } from './config/firebase-config';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import './styles/CatalogCard.css'; // Import the new CSS file
import './styles/SectionHeader.css'; // Import the new CSS module
import emptyStateIcon from './icons/classes.svg'; // Add this import

const CatalogList = () => {
  const [classes, setClasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    fetchClasses();
  }, []);

  const handleShowModal = (classData = null) => {
    setSelectedClass(classData);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const saveClass = async (classData) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('No user logged in!');
      return;
    }
  
    // Ensure 'cost' is converted to an integer
    const numericCost = parseInt(classData.cost, 10);
    if (isNaN(numericCost)) {
      console.error('Invalid cost:', classData.cost);
      return; // Prevent saving if cost is not a valid number
    }
  
    const classToSave = {
      ...classData,
      cost: numericCost, // Store the cost as an integer
      createdBy: user.uid
    };
  
    try {
      if (classData.id) {
        const classRef = doc(db, 'classes', classData.id);
        await updateDoc(classRef, classToSave);
      } else {
        await addDoc(collection(db, 'classes'), classToSave);
      }
      fetchClasses();
    } catch (error) {
      console.error('Error saving class:', error);
    }
    handleCloseModal();
  };
  


  const deleteClasses = async (classId) => {
    try {
        const classRef = doc(db, 'classes', classId);
        await deleteDoc(classRef);
        fetchClasses(); // Refresh the members list after deletion
    } catch (error) {
        console.error('Error deleting class:', error);
    }
};

  const fetchClasses = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('No user logged in!');
      return;
    }

    let baseQuery = collection(db, 'classes');
    let conditions = [where("createdBy", "==", user.uid) ];

    const q = query(baseQuery, ...conditions); 

    try {
      const querySnapshot = await getDocs(q);
      const classesData = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.class_name.localeCompare(b.class_name));
      setClasses(classesData);

      // Extract all unique tags
      const tags = new Set(classesData.flatMap(cls => cls.tags || []));
      setAllTags(Array.from(tags).map(tag => ({ value: tag, label: tag })));
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions || []);
  };

  const filteredClasses = classes.filter(item => 
    selectedTags.length === 0 || 
    selectedTags.some(tag => item.tags && item.tags.includes(tag.value))
  );

  const getCadenceClass = (cadence) => {
    switch (cadence.toLowerCase()) {
      case 'mensual':
        return 'mensual';
      case 'semanal':
        return 'semanal';
      case 'diario':
        return 'diario';
      case 'evento':
        return 'evento';
      default:
        return '';
    }
  };

  const selectStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderRadius: '20px',
      backgroundColor: '#F4EDE4',
      border: '1.5px solid #1F1F1F',
      minHeight: '36px', // Match button height
      boxShadow: 'none',
      '&:hover': {
        border: '1.5px solid #1F1F1F',
      },
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: '#1F1F1F',
      borderRadius: '50px',
    }),
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      color: '#F4EDE4',
      padding: '2px 8px',
    }),
    multiValueRemove: (baseStyles) => ({
      ...baseStyles,
      color: '#F4EDE4',
      borderRadius: '0 50px 50px 0',
      '&:hover': {
        backgroundColor: '#444',
        color: '#F4EDE4',
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isSelected ? '#1F1F1F' : state.isFocused ? '#F4EDE4' : '#FFFFFF',
      color: state.isSelected ? '#F4EDE4' : '#1F1F1F',
      '&:active': {
        backgroundColor: '#1F1F1F',
        color: '#F4EDE4',
      },
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: '10px',
    }),
  };

  return (
    <div>
      {classes.length > 0 ? (
        <>
          <div className="section-header">
            <h3 className='section-title'>{classes.length} productos</h3>
            <div className="filter-container">
              <Select
                isMulti
                name="tags"
                options={allTags}
                className="filter-multi"
                classNamePrefix="filter-multi-select"
                placeholder="Filtrar"
                onChange={handleTagChange}
                value={selectedTags}
                styles={selectStyles}
              />
            </div>
          </div>

          <div className="row">
            {filteredClasses.map((item, index) => (
              <div key={index} className="col-sm-4">
                <div className="catalog-card">
                  <div className="catalog-card-edit" onClick={() => handleShowModal(item)}>Editar</div>
                  <h5 className="catalog-card-title">{item.class_name}</h5>
                  <p className="catalog-card-price">${item.cost}</p>
                  <div className={`catalog-card-status ${getCadenceClass(item.cadence)}`}>{item.cadence}</div>
                  <p className="catalog-card-description">{item.description}</p>
                  <div className="catalog-card-tags">
                    {item.tags && item.tags.map((tag, index) => (
                      <span key={index} className="catalog-card-tag">{tag}</span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="empty-state-container">
          <img src={emptyStateIcon} alt="No products" className="empty-state-icon" />
          <h2>Aún no tienes productos</h2>
          <p>¡Manos a la obra! Crea un catálogo de productos y ofrécelo a tus clientes.</p>
          <button className="add-product-button" onClick={() => handleShowModal(null)}>
            + Agregar
          </button>
        </div>
      )}

      <AddClassModal
        show={showModal}
        handleClose={handleCloseModal}
        saveClass={saveClass}
        deleteClass={deleteClasses} 
        initialData={selectedClass}
      />

      {classes.length > 0 && (
        <div className="fab-container">
          <button className="fab" onClick={() => handleShowModal(null)}>+</button>
        </div>
      )}
    </div>
  );
};

export default CatalogList;