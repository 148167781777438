import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { RRule } from 'rrule';

const AddEventModal = ({ show, handleClose, handleSave, selectedDate, classes, activeMembers }) => {
  const [eventData, setEventData] = useState({
    event_title: '',
    start: formatDateTimeForInput(selectedDate || new Date()),
    end: formatDateTimeForInput(new Date((selectedDate || new Date()).getTime() + 3600000)),
    classId: '',
    memberId: '',
    recurrence: 'none', // Add this line
    recurrenceEndDate: '', // Add this line
    // ... other fields ...
  });

  useEffect(() => {
    if (show) {
      const now = new Date();
      const startDate = selectedDate || now;
      const nearestHour = new Date(startDate);
      nearestHour.setHours(nearestHour.getHours() + 1, 0, 0, 0);

      const endDate = new Date(nearestHour);
      endDate.setHours(endDate.getHours() + 1);

      const defaultRecurrenceEndDate = new Date(nearestHour);
      defaultRecurrenceEndDate.setMonth(defaultRecurrenceEndDate.getMonth() + 1);

      setEventData(prev => ({
        ...prev,
        start: formatDateTimeForInput(nearestHour),
        end: formatDateTimeForInput(endDate),
        recurrenceEndDate: formatDateForInput(defaultRecurrenceEndDate)
      }));
    }
  }, [show, selectedDate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData(prev => ({ ...prev, [name]: value }));
  };

  const handleRecurrenceChange = (e) => {
    const { value } = e.target;
    setEventData(prev => ({ ...prev, recurrence: value }));
  };

  const generateRRule = () => {
    if (eventData.recurrence === 'none') return null;

    const startDate = new Date(eventData.start);
    let options = {
      freq: RRule[eventData.recurrence.toUpperCase()],
      interval: 1,
      dtstart: startDate,
      until: new Date(eventData.recurrenceEndDate)
    };

    if (eventData.recurrence === 'weekly') {
      options.byweekday = [startDate.getDay()];
    }

    return new RRule(options).toString();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const rrule = generateRRule();
    const formattedEventData = {
      ...eventData,
      start: new Date(eventData.start),
      end: new Date(eventData.end),
      recurrence: rrule
    };
    handleSave(formattedEventData);
    handleClose();
  };

  function formatDateTimeForInput(date) {
    return date.toISOString().slice(0, 16);
  }

  // Add this helper function
  function formatDateForInput(date) {
    return date.toISOString().split('T')[0];
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Event Title</Form.Label>
            <Form.Control
              type="text"
              name="event_title"
              value={eventData.event_title}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="datetime-local"
              name="start"
              value={eventData.start}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="datetime-local"
              name="end"
              value={eventData.end}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Class</Form.Label>
            <Form.Control
              as="select"
              name="classId"
              value={eventData.classId}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a class</option>
              {classes.map(cls => (
                <option key={cls.id} value={cls.id}>{cls.class_name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Member</Form.Label>
            <Form.Control
              as="select"
              name="memberId"
              value={eventData.memberId}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a member</option>
              {activeMembers.map(member => (
                <option key={member.id} value={member.id}>{member.contact_name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Recurrence</Form.Label>
            <Form.Control
              as="select"
              name="recurrence"
              value={eventData.recurrence}
              onChange={handleRecurrenceChange}
            >
              <option value="none">None</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </Form.Control>
          </Form.Group>
          {eventData.recurrence !== 'none' && (
            <Form.Group>
              <Form.Label>Recurrence End Date</Form.Label>
              <Form.Control
                type="date"
                name="recurrenceEndDate"
                value={eventData.recurrenceEndDate}
                onChange={handleInputChange}
                required={eventData.recurrence !== 'none'}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Save Event
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEventModal;

