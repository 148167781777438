import React, { useContext, useState, useEffect, createContext } from 'react';
import { auth, db } from '../config/firebase-config'; // Ensure db is correctly imported
import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore document methods
import axios from 'axios'; // Import axios for Stripe onboarding status check

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [additionalUserInfo, setAdditionalUserInfo] = useState({});
  const [stripeOnboardingComplete, setStripeOnboardingComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const userRef = doc(db, "users", user.uid); // Adjust "users" to your collection name
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setAdditionalUserInfo(userDoc.data()); // Store additional user info
        } else {
          console.log("No additional user data found");
        }
      }
      setLoading(false);
    });

    return unsubscribe; // Unsubscribe on unmount
  }, []);

  const login = async (token) => {
    try {
      console.log("Starting login process...");
      const userCredential = await signInWithCustomToken(auth, token);
      const user = userCredential.user;
      console.log("User authenticated:", user.uid);
      
      // Removed Stripe onboarding status check

      return user;
    } catch (error) {
      console.error("Failed to sign in:", error);
      throw error;
    }
  };

  const value = {
    currentUser,
    additionalUserInfo, // Make additional user info available in the context
    login,
    stripeOnboardingComplete,
    setStripeOnboardingComplete,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}


/*src/contexts/AuthContext.js
import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase-config'; // Adjust the path as necessary
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe; // Unsubscribe on unmount
  }, []);

  const value = {
    currentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}*/
