import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const EditEventModal = ({ show, handleClose, handleDelete, event }) => {
  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDeleteClick = () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      handleDelete(event.id);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Event Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {event && (
          <Form>
            <Form.Group>
              <Form.Label>Event Title</Form.Label>
              <Form.Control plaintext readOnly defaultValue={event.title} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Start Time</Form.Label>
              <Form.Control plaintext readOnly defaultValue={formatDate(event.start)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>End Time</Form.Label>
              <Form.Control plaintext readOnly defaultValue={formatDate(event.end)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Class</Form.Label>
              <Form.Control plaintext readOnly defaultValue={event.extendedProps.class_name} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Member</Form.Label>
              <Form.Control plaintext readOnly defaultValue={event.extendedProps.memberName} />
            </Form.Group>
            {event.rrule && (
              <Form.Group>
                <Form.Label>Recurrence</Form.Label>
                <Form.Control plaintext readOnly defaultValue={event.rrule} />
              </Form.Group>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleDeleteClick}>
          Delete Event
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditEventModal;
