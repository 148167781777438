import React from 'react';
import { Form } from 'react-bootstrap';

const ClassSelect = ({ classes, selectedClassIds, handleClassChange }) => {
  if (!classes || classes.length === 0) {
    return <p>No hay clases disponibles.</p>;
  }

  return (
    <div className="class-select">
      {classes.map(cls => (
        <Form.Check
          key={cls.id}
          type="checkbox"
          id={`class-${cls.id}`}
          label={
            <span>
              {cls.class_name} - ${cls.cost}
              <span className="class-cadence"> ({cls.cadence})</span>
            </span>
          }
          value={cls.id}
          checked={selectedClassIds.includes(cls.id)}
          onChange={handleClassChange}
        />
      ))}
    </div>
  );
};

export default ClassSelect;