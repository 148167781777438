import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';


const AddClassModal = ({ show, handleClose, saveClass, deleteClass, initialData }) => {
  const [className, setClassName] = useState('');
  const [description, setDescription] = useState('');
  const [cadence, setCadence] = useState('');
  const [cost, setCost] = useState('');
  const [displayCost, setDisplayCost] = useState(''); // holds the formatted display value
  const [tags, setTags] = useState('');
  const [cadenceType, setCadenceType] = useState('');
  const [inventoryType, setInventoryType] = useState('service');

//INITIALIZE DATA
  useEffect(() => {
    if (show) {
      if (initialData) {
        setClassName(initialData.class_name || '');
        setDescription(initialData.description || '');
        setCadence(initialData.cadence || '');
        setCost(initialData.cost || '');
        setDisplayCost(formatCurrency(initialData.cost));
        setCadenceType(initialData.cadence === 'Mensual' ? 'recurring' : 'one-time');
        setInventoryType(initialData.inventoryType || 'service');
      } else {
        setClassName('');
        setDescription('');
        setCadence('');
        setCost('');
        setDisplayCost('');
        setCadenceType('');
        setInventoryType('service');
      }
    }
  }, [show, initialData]);


  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleCostChange = (e) => {
    const value = e.target.value;
    if (value === '' || value === '$') {
      setCost(0);
      setDisplayCost('');
      return;
    }
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    if (!isNaN(numericValue)) {
      setCost(numericValue);
      setDisplayCost(formatCurrency(numericValue));
    } else {
      setDisplayCost(value);
    }
  };

//CREATE OR UPDATE CLASS
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      class_name: className,
      description: description,
      cadence: cadence,
      cadenceType: cadenceType,
      cost: cost,
      tags: tags.split(',').map(tag => tag.trim()).filter(tag => tag !== ''),
      inventoryType: inventoryType // Changed from productType to inventoryType
    };

    // Only include the id if it exists (for updates)
    if (initialData && initialData.id) {
      dataToSubmit.id = initialData.id;
    }

    console.log('Submitting class data:', dataToSubmit);

    saveClass(dataToSubmit);
    handleClose();
  };

  // DELETE MEMNBER
  const handleDelete = () => {
    if (initialData.id) {
        deleteClass(initialData.id);
        handleClose();
    }
  };

  const handleCadenceChange = (e) => {
    const value = e.target.value;
    setCadence(value);
    setCadenceType(value === 'Mensual' ? 'recurring' : 'one-time');
  };

  return (
    <Modal show={show} onHide={handleClose} className="add-class-modal">
      <Modal.Header closeButton>
        <Modal.Title>{initialData ? 'Editar producto' : 'Agregar evento'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre del producto</Form.Label>
            <Form.Control
              type="text"
              value={className}
              onChange={e => setClassName(e.target.value)}
              required
            />
          </Form.Group>


          <Form.Group>
            <Form.Label>Costo</Form.Label>
            <Form.Control
              type="text" // use type text to allow formatting
              value={displayCost}
              onChange={handleCostChange}
              required
            />
          </Form.Group>

         
          <Form.Group>
            <Form.Label>Cadencia de cobro</Form.Label>
            <Form.Control
              as="select"
              value={cadence}
              onChange={handleCadenceChange}
              required>
              <option value="">Selecciona una opción...</option>
              <option value="Mensual">Mensual</option>
              <option value="Evento">Por Evento</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Etiquetas (separadas por comas)</Form.Label>
            <Form.Control
              type="text"
              value={tags}
              onChange={e => setTags(e.target.value)}
              placeholder="ej: yoga, principiantes, mañana"
            />
          </Form.Group>


          {/* <Form.Group>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group> */}


          <Button className="modal-button btn btn-primary" type="submit">
            {initialData && initialData.id ? 'Actualizar' : 'Agregar'}         
          </Button>

          {initialData && initialData.id && (
            <Button className="modal-button-secondary" variant="link" onClick={handleDelete}>
              Eliminar
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddClassModal; 
